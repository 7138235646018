<template>
  <div class="video-course-container">
    <div class="video-course-panels">
      <div class="video-course-menubar">
        <div class="video-course-container01">
          <div class="video-course-container02">
            <router-link to="/" class="video-course-navlink">
              <img
                alt="image"
                src="/playground_assets/logotext-fill.svg"
                class="video-course-image"
              />
            </router-link>
            <svg viewBox="0 0 1024 1024" class="video-course-icon">
              <path
                d="M128 256h768v86h-768v-86zM128 554v-84h768v84h-768zM128 768v-86h768v86h-768z"
              ></path>
            </svg>
          </div>
        </div>
        <div class="video-course-container03">
          <div class="video-course-container04">
            <svg viewBox="0 0 1024 1024" class="video-course-icon02">
              <path
                d="M406 598q80 0 136-56t56-136-56-136-136-56-136 56-56 136 56 136 136 56zM662 598l212 212-64 64-212-212v-34l-12-12q-76 66-180 66-116 0-197-80t-81-196 81-197 197-81 196 81 80 197q0 42-20 95t-46 85l12 12h34z"
              ></path>
            </svg>
            <span class="video-course-text">Cari materi...</span>
          </div>
        </div>
        <div class="video-course-container05">
          <span class="video-course-text01">Bab 1: Muqoddimah</span>
          <div class="video-course-container06">
            <span class="video-course-text02">Materi 1</span>
            <svg viewBox="0 0 1024 1024" class="video-course-icon04">
              <path
                d="M426 726l384-384-60-62-324 324-152-152-60 60zM512 86q176 0 301 125t125 301-125 301-301 125-301-125-125-301 125-301 301-125z"
              ></path>
            </svg>
          </div>
          <div class="video-course-container07">
            <span class="video-course-text03">Materi 2</span>
            <svg viewBox="0 0 1024 1024" class="video-course-icon06">
              <path
                d="M426 726l384-384-60-62-324 324-152-152-60 60zM512 86q176 0 301 125t125 301-125 301-301 125-301-125-125-301 125-301 301-125z"
              ></path>
            </svg>
          </div>
          <div class="video-course-container08">
            <span class="video-course-text04">Materi 3</span>
            <svg viewBox="0 0 1024 1024" class="video-course-icon08">
              <path
                d="M426 726l384-384-60-62-324 324-152-152-60 60zM512 86q176 0 301 125t125 301-125 301-301 125-301-125-125-301 125-301 301-125z"
              ></path>
            </svg>
          </div>
          <div class="video-course-container09">
            <span class="video-course-text05">Materi 4</span>
          </div>
          <div class="video-course-container10">
            <span class="video-course-text06">Materi 5</span>
          </div>
          <span class="video-course-text07">Bab 2: Lorem Ipsum</span>
          <div class="video-course-container11">
            <span class="video-course-text08">Materi xx</span>
          </div>
          <div class="video-course-container12">
            <span class="video-course-text09">Materi xx</span>
          </div>
          <div class="video-course-container13">
            <span class="video-course-text10">Materi xx</span>
          </div>
          <div class="video-course-container14">
            <span class="video-course-text11">Materi xx</span>
          </div>
          <div class="video-course-container15">
            <span class="video-course-text12">Materi xx</span>
          </div>
          <span class="video-course-text13">Bab 3: Lorem Ipsum</span>
          <div class="video-course-container16">
            <span class="video-course-text14">Materi xx</span>
          </div>
          <div class="video-course-container17">
            <span class="video-course-text15">Materi xx</span>
          </div>
          <div class="video-course-container18">
            <span class="video-course-text16">Materi xx</span>
          </div>
          <div class="video-course-container19">
            <span class="video-course-text17">Materi xx</span>
          </div>
          <div class="video-course-container20">
            <span class="video-course-text18">Materi xx</span>
          </div>
          <div class="video-course-container21">
            <span class="video-course-text19">Materi xx</span>
          </div>
        </div>
      </div>
      <div class="video-course-video-content">
        <div class="video-course-container22">
          <div class="video-course-container23">
            <span class="video-course-text20">Bahasa Arab Materi Empat</span>
            <span>Lorem ipsum dolor sit amet holo hampa jojo</span>
          </div>
          <div class="video-course-container24">
            <button class="video-course-button button">
              <img
                alt="image"
                src="/playground_assets/navigate_next.svg"
                class="video-course-image1"
              />
              <span class="video-course-text22">Mundur</span>
            </button>
            <button class="video-course-button1 button">
              <span class="video-course-text23">Selanjutnya</span>
              <svg viewBox="0 0 1024 1024" class="video-course-icon10">
                <path
                  d="M426 256l256 256-256 256-60-60 196-196-196-196z"
                ></path>
              </svg>
            </button>
          </div>
        </div>
        <div class="video-course-container25">
          <iframe
            src="https://www.youtube.com/embed/Yq08LfcqCSc"
            class="video-course-iframe"
          ></iframe>
        </div>
        <span class="video-course-text24">Faidah / Catatan</span>
        <span class="video-course-text25">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisl fames
          aliquet blandit fermentum in. Congue nisl porttitor suscipit
          pellentesque maecenas. Nibh enim mi turpis bibendum pellentesque
          cursus. Ipsum lectus laoreet amet, porta feugiat nibh a sapien vitae.
          Dictum tortor mauris diam lobortis a nulla. Libero eget justo quis
          sed. Massa odio netus cursus vestibulum nascetur proin. At auctor eu
          nibh amet donec mi ac ut. Vitae cursus sed tincidunt non. Sit purus in
          nunc, egestas sit nullam.
        </span>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require("axios").default;
const config = require("../config.js").default;

export default {
  name: "VideoCourse",
  beforeCreate() {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem(config.localStorage.jwtToken)}`;
    axios
      .get(config.urls.userInfo())
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response.status == 401) {
          this.$router.push(`/login-page`);
        }
      });
  },
  metaInfo: {
    title: "video-course - Fashliy - Islamic Learning Platform",
    meta: [
      {
        property: "og:title",
        content: "video-course - Fashliy - Islamic Learning Platform",
      },
    ],
  },
};
</script>

<style scoped>
.video-course-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.video-course-panels {
  height: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  justify-content: flex-start;
}
.video-course-menubar {
  flex: 0 0 auto;
  width: 15%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #34888d;
}
.video-course-container01 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  background-color: var(--dl-color-primary-teal-primary);
}
.video-course-container02 {
  flex: 0 0 auto;
  width: 100%;
  height: 70px;
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: center;
}
.video-course-navlink {
  display: contents;
}
.video-course-image {
  width: 100px;
  margin: var(--dl-space-space-unit);
  object-fit: cover;
  text-decoration: none;
}
.video-course-icon {
  fill: var(--dl-color-gray-white);
  width: 30px;
  height: 30px;
  margin: var(--dl-space-space-unit);
  opacity: 0.5;
  padding: 3px;
  border-color: var(--dl-color-gray-white);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
}
.video-course-container03 {
  flex: 0 0 auto;
  width: 100%;
  height: 70px;
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: center;
}
.video-course-container04 {
  flex: 0 0 auto;
  width: 189px;
  height: 40px;
  display: flex;
  opacity: 0.5;
  align-self: center;
  align-items: center;
  border-color: var(--dl-color-gray-white);
  border-style: solid;
  border-width: 2px;
  border-radius: 50px;
  justify-content: center;
}
.video-course-icon02 {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
  margin-left: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-halfunit);
}
.video-course-text {
  color: var(--dl-color-gray-white);
  font-size: 15px;
  margin-left: 0px;
  margin-right: var(--dl-space-space-unit);
}
.video-course-container05 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  align-self: stretch;
  align-items: flex-start;
  padding-top: 0px;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
}
.video-course-text01 {
  color: var(--dl-color-gray-white);
  margin-top: var(--dl-space-space-unit);
  font-weight: 700;
  margin-bottom: 0px;
}
.video-course-container06 {
  flex: 0 0 auto;
  height: 40px;
  display: flex;
  opacity: 0.2;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  margin-top: var(--dl-space-space-halfunit);
  align-items: center;
  border-color: var(--dl-color-gray-white);
  border-style: solid;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
  justify-content: space-between;
}
.video-course-text02 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
}
.video-course-icon04 {
  fill: var(--dl-color-gray-white);
  width: 24px;
  align-self: center;
}
.video-course-container07 {
  flex: 0 0 auto;
  height: 40px;
  display: flex;
  opacity: 0.2;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  margin-top: var(--dl-space-space-halfunit);
  align-items: center;
  border-color: var(--dl-color-gray-white);
  border-style: solid;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
  justify-content: space-between;
}
.video-course-text03 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
}
.video-course-icon06 {
  fill: var(--dl-color-gray-white);
  width: 24px;
  align-self: center;
}
.video-course-container08 {
  flex: 0 0 auto;
  height: 40px;
  display: flex;
  opacity: 0.2;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  margin-top: var(--dl-space-space-halfunit);
  align-items: center;
  border-color: var(--dl-color-gray-white);
  border-style: solid;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
  justify-content: space-between;
}
.video-course-text04 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
}
.video-course-icon08 {
  fill: var(--dl-color-gray-white);
  width: 24px;
  align-self: center;
}
.video-course-container09 {
  flex: 0 0 auto;
  height: 40px;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  margin-top: var(--dl-space-space-halfunit);
  align-items: center;
  border-color: var(--dl-color-gray-white);
  border-style: solid;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
  justify-content: space-between;
  background-color: var(--dl-color-gray-white);
}
.video-course-text05 {
  color: var(--dl-color-primary-teal-primary);
  font-size: 16px;
}
.video-course-container10 {
  flex: 0 0 auto;
  height: 40px;
  display: flex;
  opacity: 0.2;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  margin-top: var(--dl-space-space-halfunit);
  align-items: center;
  border-color: var(--dl-color-gray-white);
  border-style: solid;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
  justify-content: space-between;
}
.video-course-text06 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
}
.video-course-text07 {
  color: var(--dl-color-gray-white);
  margin-top: var(--dl-space-space-unit);
  font-weight: 700;
  margin-bottom: 0px;
}
.video-course-container11 {
  flex: 0 0 auto;
  height: 40px;
  display: flex;
  opacity: 0.2;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  margin-top: var(--dl-space-space-halfunit);
  align-items: center;
  border-color: var(--dl-color-gray-white);
  border-style: solid;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
  justify-content: space-between;
}
.video-course-text08 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
}
.video-course-container12 {
  flex: 0 0 auto;
  height: 40px;
  display: flex;
  opacity: 0.2;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  margin-top: var(--dl-space-space-halfunit);
  align-items: center;
  border-color: var(--dl-color-gray-white);
  border-style: solid;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
  justify-content: space-between;
}
.video-course-text09 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
}
.video-course-container13 {
  flex: 0 0 auto;
  height: 40px;
  display: flex;
  opacity: 0.2;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  margin-top: var(--dl-space-space-halfunit);
  align-items: center;
  border-color: var(--dl-color-gray-white);
  border-style: solid;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
  justify-content: space-between;
}
.video-course-text10 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
}
.video-course-container14 {
  flex: 0 0 auto;
  height: 40px;
  display: flex;
  opacity: 0.2;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  margin-top: var(--dl-space-space-halfunit);
  align-items: center;
  border-color: var(--dl-color-gray-white);
  border-style: solid;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
  justify-content: space-between;
}
.video-course-text11 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
}
.video-course-container15 {
  flex: 0 0 auto;
  height: 40px;
  display: flex;
  opacity: 0.2;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  margin-top: var(--dl-space-space-halfunit);
  align-items: center;
  border-color: var(--dl-color-gray-white);
  border-style: solid;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
  justify-content: space-between;
}
.video-course-text12 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
}
.video-course-text13 {
  color: var(--dl-color-gray-white);
  margin-top: var(--dl-space-space-unit);
  font-weight: 700;
  margin-bottom: 0px;
}
.video-course-container16 {
  flex: 0 0 auto;
  height: 40px;
  display: flex;
  opacity: 0.2;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  margin-top: var(--dl-space-space-halfunit);
  align-items: center;
  border-color: var(--dl-color-gray-white);
  border-style: solid;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
  justify-content: space-between;
}
.video-course-text14 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
}
.video-course-container17 {
  flex: 0 0 auto;
  height: 40px;
  display: flex;
  opacity: 0.2;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  margin-top: var(--dl-space-space-halfunit);
  align-items: center;
  border-color: var(--dl-color-gray-white);
  border-style: solid;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
  justify-content: space-between;
}
.video-course-text15 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
}
.video-course-container18 {
  flex: 0 0 auto;
  height: 40px;
  display: flex;
  opacity: 0.2;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  margin-top: var(--dl-space-space-halfunit);
  align-items: center;
  border-color: var(--dl-color-gray-white);
  border-style: solid;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
  justify-content: space-between;
}
.video-course-text16 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
}
.video-course-container19 {
  flex: 0 0 auto;
  height: 40px;
  display: flex;
  opacity: 0.2;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  margin-top: var(--dl-space-space-halfunit);
  align-items: center;
  border-color: var(--dl-color-gray-white);
  border-style: solid;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
  justify-content: space-between;
}
.video-course-text17 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
}
.video-course-container20 {
  flex: 0 0 auto;
  height: 40px;
  display: flex;
  opacity: 0.2;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  margin-top: var(--dl-space-space-halfunit);
  align-items: center;
  border-color: var(--dl-color-gray-white);
  border-style: solid;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
  justify-content: space-between;
}
.video-course-text18 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
}
.video-course-container21 {
  flex: 0 0 auto;
  height: 40px;
  display: flex;
  opacity: 0.2;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  margin-top: var(--dl-space-space-halfunit);
  align-items: center;
  border-color: var(--dl-color-gray-white);
  border-style: solid;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
  justify-content: space-between;
}
.video-course-text19 {
  color: var(--dl-color-gray-white);
  font-size: 16px;
}
.video-course-video-content {
  flex: 0 0 auto;
  width: 85%;
  height: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: flex-start;
}
.video-course-container22 {
  width: 100%;
  display: flex;
  align-self: flex-start;
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  justify-content: space-between;
}
.video-course-container23 {
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: stretch;
  flex-direction: column;
  justify-content: space-between;
}
.video-course-text20 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-halfunit);
}
.video-course-container24 {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}
.video-course-button {
  color: var(--dl-color-primary-teal-primary);
  display: flex;
  font-size: 18px;
  align-self: center;
  transition: 0.3s;
  align-items: center;
  font-weight: 700;
  margin-left: var(--dl-space-space-unit);
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-primary-teal-primary);
  border-width: 1px;
  margin-right: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-unit);
  border-radius: 50px;
  padding-right: var(--dl-space-space-twounits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-halfunit);
  background-color: var(--dl-color-gray-white);
}
.video-course-button:hover {
  color: var(--dl-color-gray-white);
  background-color: var(--dl-color-gray-black);
}
.video-course-image1 {
  width: 24px;
  object-fit: cover;
}
.video-course-text22 {
  margin-left: var(--dl-space-space-halfunit);
  margin-right: 0px;
}
.video-course-button1 {
  color: var(--dl-color-gray-white);
  display: flex;
  font-size: 18px;
  align-self: center;
  transition: 0.3s;
  align-items: center;
  font-weight: 700;
  padding-top: var(--dl-space-space-halfunit);
  border-width: 0px;
  padding-left: var(--dl-space-space-twounits);
  border-radius: 50px;
  padding-right: var(--dl-space-space-unit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-halfunit);
  background-color: var(--dl-color-primary-teal-primary);
}
.video-course-button1:hover {
  color: var(--dl-color-gray-white);
  background-color: var(--dl-color-gray-black);
}
.video-course-text23 {
  margin-right: var(--dl-space-space-halfunit);
}
.video-course-icon10 {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
}
.video-course-container25 {
  width: 100%;
  height: 100%;
  display: flex;
  align-self: stretch;
  align-items: stretch;
  flex-direction: column;
  justify-content: space-between;
}
.video-course-iframe {
  width: 100%;
  height: 720px;
  padding-bottom: var(--dl-space-space-threeunits);
}
.video-course-text24 {
  font-size: 21px;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-unit);
}
.video-course-text25 {
  opacity: 0.5;
}
</style>
